import React, { useEffect } from 'react';
import { Hidden } from '@mui/material';
import { useLocation } from 'react-router-dom';
import {
  AuthGridContainer, InnerGridContainer,
  LogoTypo, LogoGridContainer, InputsGridContainer, InputsPaper,
  InputsPaperContainer, InputsHeaderTypo, TypoDesc, TypoWrapper, Img, SubTypo, FormWrapper, OgaAdminLogo, WarehouseLg, MpAuthImage
} from './authContainer.styles';
import WareHouseImg1 from '../../assets/images/warehouseAuthImg1.png';
import WareHouseImg2 from '../../assets/images/warehouseAuthImg2.png';

import Register from './register';
import Login from './login';

import { useStateValue } from '../../providers/stateProvider';
import { BWave } from '../../assets/svgs';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './resetPassword';

const AuthContainer = () => {
  const [, dispatch] = Object.values(useStateValue());
  const { pathname } = useLocation();

  useEffect(() => {
    dispatch({ type: 'hideNavbar', payload: true });
  }, [dispatch]);
  const url = window.location.origin;
  const location = url?.includes('warehouse');
  const returnHeader = () => {
    switch (pathname) {
      case '/':
        return 'Sign in to OGApharmacy';
      case '/register':
        return 'Sign up on OGApharmacy';
      default:
        return 'Forgot Password?';
    }
  };

  const returnComponent = () => {
    switch (pathname) {
      case '/':
        return <Login />;
      case '/register':
        return <Register />;
      case '/forgot-password':
        return <ForgotPassword />;
      default:
        return <ResetPassword />;
    }
  };
  return (
    <AuthGridContainer container>
      <InnerGridContainer item container>
        <Hidden mdDown>
          <LogoGridContainer item container xs={12} md={6} lg={6} style={{ background: location ? '#235A91' : ' ' }}>
            {location ? (
              (pathname === '/register' ? (
                <Img alt="PharmIQ" src={WareHouseImg1} />
              ) : (
                <Img alt="PharmIQ" src={WareHouseImg2} />
              ))
            ) : (
              <MpAuthImage />
            )}
            <TypoWrapper>
              <LogoTypo variant="h5" style={{ color: location ? '#FFFFFF' : ' ' }}>
                Quality Guaranteed
              </LogoTypo>
              <TypoDesc style={{ color: location ? '#FFFFFF' : '' }}>
                Your reliable source for trusted pharmaceutical products
              </TypoDesc>
            </TypoWrapper>
          </LogoGridContainer>
        </Hidden>
        <InputsGridContainer item container xs={12} md={6} lg={6}>
          <InputsPaper item container>
            <InputsPaperContainer item>
              {location ? (
                <WarehouseLg />
              ) : (
                <OgaAdminLogo />
              )}
              <InputsHeaderTypo variant="h6">
                {returnHeader()}
              </InputsHeaderTypo>
              <SubTypo variant="h6">
                {location ? (
                  <>
                    <BWave fontSize="1.4rem" />
                    Welcome back
                  </>
                ) : (
                  pathname === '/register'
                    ? 'Welcome, Let’ get you started.'
                    : pathname === '/forgot-password'
                      ? 'Please enter the email address you registered with, you will be sent a link to reset your password.'
                      : 'Welcome back to marketplace, continue shopping with us.'
                )}
              </SubTypo>
              <FormWrapper>
                {returnComponent()}
              </FormWrapper>
            </InputsPaperContainer>
          </InputsPaper>
        </InputsGridContainer>
      </InnerGridContainer>
    </AuthGridContainer>
  );
};

export default AuthContainer;
