import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import jssPreset from '@mui/styles/jssPreset';
import { JssProvider } from 'react-jss';
import { create } from 'jss';
import registerServiceWorker from './registerServiceWorker';
import getApolloClient from './graphql';
import theme from './assets/theme';
import App from './components/App';
import StateProvider from './providers/stateProvider';
import './assets/styles/style.css';
import CToaster from './components/shared/CToaster';

const generateClassName = createGenerateClassName();
const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for injecting the styles in the DOM.
  insertionPoint: document.getElementById('jss-insertion-point')
});
const root = ReactDOM.createRoot(document.getElementById('app'));
getApolloClient().then((client) => {
  root.render(
    <React.StrictMode>
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CToaster />
            <BrowserRouter>
              <ApolloProvider client={client}>
                <StateProvider>
                  <App />
                </StateProvider>
              </ApolloProvider>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </JssProvider>
    </React.StrictMode>
  );
});

if (module.hot) {
  module.hot.accept();
}

registerServiceWorker();
